<template>
  <div class="registered-box">
    <!-- 头部 -->
    <swipeTopVue
      :top_img="top_img"
      :ZHtitle="ZHtitle"
      :ENtitle="ENtitle"
    ></swipeTopVue>
    <!-- 填写个人信息 -->
    <div class="getUserInfo">
      <van-form @submit="onSubmit">
        <div class="formItem">
          <span class="titleName">用户名<span class="icon">*</span></span>
          <van-field
            v-model="username"
            name="username"
            placeholder="请输入用户姓名"
            :rules="[
              { required: true, message: '请输入用户姓名' },
              { validator: checkUserName, message: '用户名已存在' },
            ]"
          />
        </div>
        <div class="formItem">
          <span class="titleName">密码<span class="icon">*</span></span>
          <van-field
            v-model="password"
            type="password"
            name="password"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请输入密码' }]"
          />
        </div>
        <div class="formItem">
          <span class="titleName">确认密码<span class="icon">*</span></span>
          <van-field
            v-model="password1"
            type="password"
            name="password1"
            placeholder="请再次输入密码"
            :rules="[
              { required: true, message: '请再次输入密码' },
              { validator: chenkPassword, message: '两次密码不一致' },
            ]"
          />
        </div>
        <div class="formItem">
          <span class="titleName">手机号<span class="icon">*</span></span>
          <van-field
            v-model="phone"
            name="phone"
            placeholder="请输入手机号"
            :rules="[
              { required: true, message: '请输入手机号' },
              {
                pattern:
                  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                message: '手机号不正确',
                trigger: 'onBlur',
              },
            ]"
          />
        </div>
        <div class="formItem">
          <span class="titleName">验证码<span class="icon">*</span></span>
          <van-field
            v-model="code"
            name="code"
            placeholder="请输入收到的验证码"
            :rules="[{ required: true, message: '请输入收到的验证码' }]"
          >
            <template #button>
              <van-button
                @click="getCode"
                v-if="!isClickCode"
                native-type="button"
                size="small"
                type="info"
                >发送验证码</van-button
              >
              <van-button
                disabled
                v-else
                native-type="button"
                size="small"
                type="info"
                >{{ codeTime }} S</van-button
              >
            </template>
          </van-field>
        </div>

        <div style="margin: 60px 0; width: 80%">
          <van-button round block type="info" native-type="submit"
            >注册</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import swipeTopVue from "../../components/swipeTop.vue";
import { userRegistered, getCheckCode, checkUserName } from "../../api/path";
export default {
  components: {
    swipeTopVue,
  },
  data() {
    return {
      top_img:
        "https://sdg-minisite-prod-s3.s3.cn-northwest-1.amazonaws.com.cn/SDGimages/menu-background/ppjs-banner-4-1.jpg",
      ZHtitle: "注册",
      ENtitle: "REGISTERED",
      username: "", // 用户名
      password: "", // 密码
      password1: "", //确认密码
      phone: "", // 手机号
      code: "", // 验证码
      codeTime: this.$cookies.get("codeTime") || 60,
      isClickCode: false,
      userName: true,
    };
  },
  mounted() {
    if (this.$cookies.get("codeTime")) {
      this.isClickCode = true;
      this.getCode();
    }
  },
  methods: {
    // 验证用户名
    checkUserName(value) {
      let data = {
        username: value,
      };
      return checkUserName(data).then((res) => {
        return res.data;
      });
    },
    // 注册
    onSubmit(value) {
      // setTimeout(() => {
      //   this.$router.replace({ path: "userInfoPage" });
      // }, 1500);
      userRegistered(JSON.stringify(value)).then((res) => {
        if (res.code == 200) {
          this.$cookies.set("userInfo", res.data);
          this.$toast.success("注册成功,请完善个人信息");
          setTimeout(() => {
            this.$router.replace({ path: "userInfoPage" });
          }, 1500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    // 验证密码
    chenkPassword(value) {
      return value === this.password;
    },
    // 获取验证码
    getCode() {
      let data = {
        phone: this.phone,
      };
      getCheckCode(data).then((res) => {
        if (res.code == 200) {
          this.$toast.success("验证码已发送，请注意查收");
          this.isClickCode = true;
          let Countdown = setInterval(() => {
            this.codeTime--;
            this.$cookies.set("codeTime", this.codeTime);
            if (this.codeTime == 0) {
              clearInterval(Countdown);
              this.isClickCode = false;
              this.codeTime = 60;
              this.$cookies.remove("codeTime");
            }
          }, 1000);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.registered-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .getUserInfo {
    flex: 1;
    background-color: #740709;
    .titleName {
      display: inline-block;
      margin: 40px 0 10px 0;
      color: #ffffff;
      .icon {
        color: red;
      }
    }
    .van-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .formItem {
        width: 90%;
      }
      .van-field {
        padding: 4px 16px;
        border-radius: 10px;
      }
    }
  }
}
/deep/ .van-button--small {
  width: 80px;
}
</style>
