var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"registered-box"},[_c('swipeTopVue',{attrs:{"top_img":_vm.top_img,"ZHtitle":_vm.ZHtitle,"ENtitle":_vm.ENtitle}}),_c('div',{staticClass:"getUserInfo"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"formItem"},[_c('span',{staticClass:"titleName"},[_vm._v("用户名"),_c('span',{staticClass:"icon"},[_vm._v("*")])]),_c('van-field',{attrs:{"name":"username","placeholder":"请输入用户姓名","rules":[
            { required: true, message: '请输入用户姓名' },
            { validator: _vm.checkUserName, message: '用户名已存在' },
          ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('div',{staticClass:"formItem"},[_c('span',{staticClass:"titleName"},[_vm._v("密码"),_c('span',{staticClass:"icon"},[_vm._v("*")])]),_c('van-field',{attrs:{"type":"password","name":"password","placeholder":"请输入密码","rules":[{ required: true, message: '请输入密码' }]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"formItem"},[_c('span',{staticClass:"titleName"},[_vm._v("确认密码"),_c('span',{staticClass:"icon"},[_vm._v("*")])]),_c('van-field',{attrs:{"type":"password","name":"password1","placeholder":"请再次输入密码","rules":[
            { required: true, message: '请再次输入密码' },
            { validator: _vm.chenkPassword, message: '两次密码不一致' },
          ]},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}})],1),_c('div',{staticClass:"formItem"},[_c('span',{staticClass:"titleName"},[_vm._v("手机号"),_c('span',{staticClass:"icon"},[_vm._v("*")])]),_c('van-field',{attrs:{"name":"phone","placeholder":"请输入手机号","rules":[
            { required: true, message: '请输入手机号' },
            {
              pattern:
                /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
              message: '手机号不正确',
              trigger: 'onBlur',
            },
          ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',{staticClass:"formItem"},[_c('span',{staticClass:"titleName"},[_vm._v("验证码"),_c('span',{staticClass:"icon"},[_vm._v("*")])]),_c('van-field',{attrs:{"name":"code","placeholder":"请输入收到的验证码","rules":[{ required: true, message: '请输入收到的验证码' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [(!_vm.isClickCode)?_c('van-button',{attrs:{"native-type":"button","size":"small","type":"info"},on:{"click":_vm.getCode}},[_vm._v("发送验证码")]):_c('van-button',{attrs:{"disabled":"","native-type":"button","size":"small","type":"info"}},[_vm._v(_vm._s(_vm.codeTime)+" S")])]},proxy:true}]),model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('div',{staticStyle:{"margin":"60px 0","width":"80%"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("注册")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }